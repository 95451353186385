<template>
  <VCard class="px-2 py-2 mb-2">
      <div class="d-flex justify-conten-space-betwee align-center">
        <VRow no-gutters align="center">
        <VCol cols="auto" lg="3" md="3" sm="8">
          <VTextField
            dense
            prepend-inner-icon="mdi-magnify"
            :placeholder="$t('form.search')"
            v-model="searchString"
            clearable
            :loader-height="5"
            @input="onSearch"
            solo
            :loading="searchLoading"
            hide-details
          />
        </VCol>
        <VCol
         cols="auto" lg="3" md="3" sm="8"
         :class="$vuetify.breakpoint.smAndDown ? 'my-3' :'mx-3'"
         >
          <VSelect
            dense
            clearable
            :items="Object.values(availableSectors)"
            :item-value="'uuid'"
            :item-text="'name'"
            v-model="chosenSector"
            solo
            :no-data-text="$t('table.noData')"
            :label="$t('form.sector')"
            hide-details
            :menu-props="{bottom:true,offsetY:true}"
          ></VSelect>
        </VCol>
        <VCol cols="auto" lg="3" md="3" sm="8">
          <VSelect
            v-if="permissions.can_search"
            dense
            clearable
            solo
            :items="Object.values(depots)"
            :item-value="'uuid'"
            :item-text="'name'"
            v-model="chosenDeports"
            :no-data-text="$t('table.noData')"
            :label="$t('orderDialog.warehouse')"
            hide-details
            :menu-props="{bottom:true,offsetY:true}"
            color="success"
            item-color="success"
          ></VSelect>
        </VCol>
        </VRow>
        <VCol  cols="auto">
          <VBtn
            class="success-bg text-white text-transform-none br-10"
            @click="createDialog = true"
            v-if="permissions.can_create_cell"
          >
            <VIcon :left="!$vuetify.breakpoint.mdAndDown">mdi-plus</VIcon>
            <div v-if="!$vuetify.breakpoint.mdAndDown">
            {{ $t('cell.create_cell') }}
            </div>
          </VBtn>
        </VCol>
      </div>
      <VDivider class="mt-3"/>
  <v-simple-table class="my-table" :dense="!isLarge">
    <template v-slot:default>
      <thead>
          <tr>
            <th class="text-center xs"><VIcon dense>mdi-cogs</VIcon></th>
            <th class="xs"></th>
            <th class="font-weight-bold">{{$t('table.name')}}</th>
            <th class="font-weight-bold">{{$t('table.ns_code')}}</th>
            <th class="font-weight-bold">{{$t('cell.parent_cell')}}</th>
            <th class="font-weight-bold">{{$t('table.sector')}}</th>
            <th class="font-weight-bold text-center">{{$t('table.status')}}</th>
          </tr>
      </thead>
      <tbody name="fade" is="transition-group">
      <CellRow
        v-for="(cell, index) in cells"
        :key="cell.uuid"
        :cell="cell"
        :cells="cells"
        :index="index"
        @delete="onDeleteCell"
        @toggleRow="toggleRow"
        :class="{'active-row' : activeRow == cell.uuid}"
      />
      </tbody>
    </template>
   </v-simple-table>
    <div v-if="isLoadingFilter">
      <v-progress-linear indeterminate />
    </div>
    <div class="text-center mt-1" v-if="!availableCells.length && !isLoadingFilter">
      {{ $t("table.noData") }}
    </div>
    <!-- <div class="text-center mt-3">
      <VBtn
        depressed
        color="blue"
        outlined
        dark
        v-if="!loadMoreDisabled"
        @click="nextPage"
        class="br-10"
      >
        Завантажити більше
      </VBtn>
    </div> -->
    <div v-observer="{nextPage:nextPage}"
      class="observer" v-if="!loadMoreDisabled && !searchLoading"></div>
    <CreateCellDialog
      v-if="permissions.can_create_cell"
      :visible="createDialog"
      @close="createDialog = false"
      :sectors="sectors"
    />
  </VCard>
</template>

<script>
import _ from 'lodash';
import depotService from '../../services/request/depot/depotService';
import notifications from '../../mixins/notifications';
import language from '../../mixins/language';
import CreateCellDialog from './CreateCellDialog.vue';
import EventBus from '../../events/EventBus';
import loader from '../../mixins/loader';
import user from '../../mixins/user';
import CellRow from './CellRow.vue';
import paginationMixin from '../../mixins/paginationMixin';
import { uniqueByUUID} from '../../mixins/helpers';
import SizeUi from '../../mixins/SizeUi';
import {mapActions, mapGetters} from "vuex";
import * as getterTypes from "@/store/modules/pageLoadTime/types/getters";
import * as actionTypes from "@/store/modules/pageLoadTime/types/actions";

export default {
  name: 'CellsComponent',
  mixins: [
    user, notifications,
    language, loader,
    paginationMixin,
    SizeUi,
  ],
  components: {
    CreateCellDialog,
    CellRow,
  },
  data: () => ({
    isLoadingFilter: false,
    cells: [],
    depots: [],
    sectors: [],
    searchString: '',
    chosenSector: '',
    chosenDeports: '',
    activeView: 'list',
    createDialog: false,
    searchLoading: false,
    availableSectors: '',
    availableCells: [],
    selectedDepotUuid: '',
    selectedSectorUuid: '',
    loadMoreDisabled: false,
    activeRow: '',
    loadTime: 0
  }),
  watch: {
    async chosenSector() {
      this.onSearchFilter();
    },
    async chosenDeports() {
      this.onSearchFilter();
    },
  },
  methods: {
    ...mapActions('pageLoadTime', {
      setPageLoadTime: actionTypes.SET_PAGE_LOAD_TIME,
    }),
    checkLoadTime() {
      const endTime = performance.now();
      this.loadTime = endTime - this.startTime;
      this.setPageLoadTime(this.loadTime)
    },
    toggleRow(e) {
      this.activeRow = e;
    },
    async emitPageChange() {
      try {
        this.reorderNumbers();
        this.isLoadingFilter = true;
        await this.getCellsList();
        this.isLoadingFilter = false;
      } catch (e) {
        console.log(e)
        this.isLoadingFilter = false;
      }
    },
    async onSearchFilter(updated) {
      const params = {};
      if (!updated) {
        this.page = 1;
      }
      params.offset = updated ? 0 : (this.page - 1) * this.perPage;
      params.limit = updated ? this.largeLimit : this.perPage;
      if (this.searchString) {
        params.name = this.searchString;
        params.uselike = true;
      }
      if (this.chosenSector) {
        params.uuid_sector = this.chosenSector;
      }
      if (this.chosenDeports) {
        params.sector_details__uuid_deport = this.chosenDeports;
      }
      this.cells = await depotService.getCellsList({
        ...params,
        order: 'DESC',
        orderby: 'time_created',
      });
      this.availableCells = this.cells;
      this.loadMoreDisabled = this.cells.length < this.perPage;
    },
    onSearch: _.debounce(function() {
      this.onSearchFilter();
    }, 500),
    // async onSearch() {
    //   this.page = 1;
    //   const params = [];
    //   if (this.chosenSector) {
    //     // eslint-disable-next-line no-param-reassign
    //     params.uuid_sector = this.chosenSector;
    //   }
    //   if (this.searchString) {
    //     this.cells = await depotService.getCellsList({
    //       limit: this.perPage,
    //       offset: 0,
    //       name: this.searchString,
    //       uselike: true,
    //       ...params,
    //     });
    //   } else {
    //     this.cells = await depotService.getCellsList({
    //       limit: this.perPage,
    //       offset: 0,
    //       ...params,
    //     });
    //   }
    //   this.loadMoreDisabled = this.cells.length < this.perPage;
    // },
    async getCellsList(params) {
      try {
        this.searchLoading = true;
        const newItems = await depotService.getCellsList({
          limit: this.perPage,
          offset: (this.page - 1) * this.perPage,
          ...params,
          order: 'DESC',
          orderby: 'time_created',
        });
        this.cells.push(...newItems);
        this.cells = uniqueByUUID(this.cells);
        this.loadMoreDisabled = newItems.length < this.perPage;
        this.availableCells = this.cells;
        this.searchLoading = false;
      } catch (e) {
        this.searchLoading = false;
        this.setLoading(false);
        console.log(e)
      }
    },
    async onDeleteCell(uuid) {
      try {
        this.setLoading(true);
        await depotService.deleteCell(uuid);
        this.setLoading(false);
        this.setSuccessNotification('Комірку було успішно видалено!');
        this.page = 1;
        this.cells = await depotService.getCellsList({
          limit: this.perPage,
          offset: (this.page - 1) * this.perPage,
          order: 'DESC',
          orderby: 'time_created',
        });
        this.loadMoreDisabled = this.cells.length < this.perPage;
      } catch (e) {
        this.setLoading(false);
        console.log(e)
      }
    },
    async fetchFirstPage() {
      try {
        this.page = 1;
        this.setLoading(true);
        this.cells = await depotService.getCellsList({
          limit: this.perPage,
          offset: 0,
          order: 'DESC',
          orderby: 'time_created',
        });
        this.availableCells = this.cells;
        this.setLoading(false);
      } catch (e) {
        this.setLoading(false);
        console.log(e)
      }
    },
    onParams() {
      const params = [];
      if (this.state.id > 0) {
        params.push({
          id_state: this.state.id,
        });
      }
      if (this.deport_uuid > 0) {
        params.push({
          uuid_deport: this.deport_uuid,
        });
      }
      return params;
    },
  },
  async mounted() {
    EventBus.$on('filter-sectors', async (data) => {
      // orderDialog('params', data);
      this.sectors = await depotService.getSectorsList({
        uuid_deport: data.uuid_deport,
      });
    });
    EventBus.$on('cell-created', async () => {
      await this.onSearchFilter();
    });
    EventBus.$on('cell-updated', async () => {
      await this.onSearchFilter(true);
    });
    try {
      this.setLoading(true);
      await this.getCellsList();
      this.depots = await depotService.getDepotsList();
      this.sectors = await depotService.getSectorsList();
      this.availableSectors = this.sectors;
      this.setLoading(false);
    } catch (e) {
      this.setLoading(false);
      console.log(e)
    }
    this.checkLoadTime();
  },
  computed: {
    ...mapGetters('pageLoadTime', {
      startTime: getterTypes.GET_START_TIME,
    }),
    nextPageDisabled() {
      return this.cells.length < this.perPage;
    },
  },
};
</script>

<style lang="scss">
.pagination-items {
  &-header, &-row {
    margin-bottom: 2px;
    > div {
      padding: 10px 0;
      &:nth-child(1) {
        text-align: center;
        width: 5%;
      }
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        width: 25%;
      }
      &:nth-child(5) {
        text-align: center;
        width: 15%;
      }
    }
  }
}
</style>
