<template>
  <tr>
    <td class="text-center">
      <VBtn
        @click="openUpdateDialog(cell.uuid)"
        small color="primary"
        class="btn-edit"
        :class="{sm : !isLarge}"
      >
      <v-icon dense>mdi-pencil</v-icon>
      </VBtn>
      <!-- <VIcon color="red" @click="deleteDialog = true">mdi-delete</VIcon> -->
    </td>
    <td class="text-center">
      <v-icon :size="isLarge ? '40' : '30'">mdi-table-merge-cells</v-icon>
    </td>
    <td>{{ cell.name }}</td>
    <td class="copyTxt" @click="copyToClipboard(cell.ns_code)">{{ cell.ns_code }}</td>
    <td>{{ cell.parent_details.name || '--' }}</td>
    <td>{{ getSectorDetails() }}</td>
    <td class="text-center">
      <div v-if="this.cell?.id_state==='700'">
        <div class="new__wrapper" :class="{ sm: !isLarge }">
          <span class="transparent">
            {{ $t("table.new") }}
          </span>
        </div>
      </div>
      <div v-else-if="this.cell?.id_state==='720'">
        <v-tooltip top>
         <template v-slot:activator="{ on, attrs }">
           <v-icon
             v-bind="attrs"
             v-on="on"
           >
             mdi-lock-outline
           </v-icon>
         </template>
         <span>{{ $t("supply.blocked") }}</span>
       </v-tooltip>
      </div>

    </td>

    <UpdateCellDialog
      :visible="updateDialog"
      @close="updateDialog = false"
      :cell="cell"
      :sectors="sectors"
      :items="items"
      v-if="updateDialog"
    />
    <DeleteAddressDialog
      :visible="deleteDialog"
      :uuid="cell.uuid"
      @close="deleteDialog = false"
      @delete="
      $emit('delete', cell.uuid);
      deleteDialog = false;
      "
    />
  </tr>
</template>

<script>
import notifications from "@/mixins/notifications";
import UpdateCellDialog from './EditCellDialog.vue';
import DeleteAddressDialog from '../dialog/ConfirmDeleteDialog.vue';
import depotService from '../../services/request/depot/depotService';
import SizeUi from '../../mixins/SizeUi';

export default {
  name: 'CellRow',
  mixins: [SizeUi, notifications],
  props: {
    cells: {
      require: true,
    },
    cell: {
      required: true,
    },
    index: {
      required: true,
    },
  },
  components: {
    UpdateCellDialog,
    DeleteAddressDialog,
  },
  data: () => ({
    updateDialog: false,
    deleteDialog: false,
    sectors: [],
    items: [],
  }),
  methods: {
    async openUpdateDialog(cell) {
      try {
        this.sectors = await depotService.getSectorsList();
        this.items = await depotService.getAllItems({
          uuid_cell: this.cell.uuid,
          groupby: 'option_details.name',
        });
        this.updateDialog = true;
        this.$emit('toggleRow', cell);
      } catch (e) {
        console.log(e)
      }
    },
    getSectorDetails() {
      return this.cell?.sector_details?.name;
    },
    async copyToClipboard(e) {
      try {
        await navigator.clipboard.writeText(e);
        this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
      } catch (e) {
        console.log(e)
      }
    },
  },
  computed: {
    background() {
      if (this.index % 2 === 0) {
        return '#F8F8F8';
      }
      return 'white';
    },
  },
};
</script>

<style scoped lang="scss">
.td {
  height: 40px;
  padding: 4px 16px !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 32px;
  color: #676767;
  text-align: left;
  word-break: break-all;
  height: auto;
  border-left: 1px solid #f2f2f2;
  &:last-child {
    padding: 4px;
    border-right: 1px solid #f2f2f2;
  }
}
.ico {
  padding: 0 !important;
}
</style>
